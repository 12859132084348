@layer base {
  .main {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
}

.cardBody {
  margin: var(--sk-space-16);
}

.richText {
  font-size: var(--sk-typography-body-1-regular-font-size);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.richText * {
  color: var(--sk-color-grey-900);
}

.richText p {
  margin: 0;
}

.richText p:empty {
  display: none;
}

.richText img,
.richText iframe {
  width: 100%;
  height: auto;
}

.richText a:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

}