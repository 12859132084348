@layer base {
  .loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  border-radius: var(--sk-radius-2);
  background-color: var(--sk-color-grey-050);
}

.loading__withBorderRadius {
  border-radius: var(--sk-radius-16);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

}